.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 4rem;
  box-sizing: border-box;
  gap: 1rem;
}

.customRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 4rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 80px;
}

.logoContainer {
  aspect-ratio: 1;
  height: 80px;
}

.logoContainer img {
  width: 100%;
  height: 100%;
}

.githubContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  aspect-ratio: 1;
  height: 80px;
}

.githubContainer img {
  width: 40%;
  height: 40%;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.table-column {
  flex-shrink: 1;
}

.column:not(.table-column) {
  width: 100%;
}